import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Category, Props as CategoryProps } from './Category'

export interface Props {
  claim?: string
  categories: CategoryProps[]
}

export const GalleryCategories = memo(function GalleryCategories({
  claim,
  categories,
}: Props) {
  if (!categories) {
    return null
  }

  return (
    <Container>
      {claim ? <Claim>{claim}</Claim> : null}
      <Categories row wrap>
        {categories.map((item, index) => (
          <Category key={index} {...item} />
        ))}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 7.5rem;

  @media (max-width: 1023px) {
    margin: 10rem auto 5rem;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  letter-spacing: 0.2rem;
  line-height: 1.375rem;
  margin-bottom: 9.0625rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-bottom: 5rem;
  }
`

const Categories = styled(FlexBox)``
